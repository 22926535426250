<template>
  <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.68372 6.15527V9.06395" stroke="currentColor" stroke-width="2" />
    <path d="M13.2898 5.77832L14.0426 8.58789" stroke="currentColor" stroke-width="2" />
    <path d="M15.9525 4.0874L18.0093 6.14415" stroke="currentColor" stroke-width="2" />
    <path d="M6.25134 5.77832L5.49852 8.58789" stroke="currentColor" stroke-width="2" />
    <path d="M3.5885 4.19434L1.53175 6.25108" stroke="currentColor" stroke-width="2" />
    <path d="M1.39969 1.12133C4.04184 4.92006 11.1231 10.2383 18.3108 1.12133" stroke="currentColor" stroke-width="2" />
  </svg>
</template>
